import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageHeader from "../components/page_header"

export const query = graphql`
  query($id: String!) {
    page: wordpressPage(id: { eq: $id }) {
      id
      slug
      title
      acf {
        description
        banner_image {
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 2400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
        yoast_wpseo_facebook_title
        yoast_wpseo_facebook_description
        yoast_wpseo_facebook_type
        yoast_wpseo_facebook_image
        yoast_wpseo_twitter_title
        yoast_wpseo_twitter_description
        yoast_wpseo_twitter_image
        yoast_wpseo_social_url
        yoast_wpseo_company_or_person
        yoast_wpseo_person_name
        yoast_wpseo_company_name
        yoast_wpseo_company_logo
        yoast_wpseo_website_name
      }
    }
  }
`

const Page = ({ data }) => {
  const page = data.page
  const meta = data.page.yoast_meta

  return (
    <Layout>
      <SEO
        title={meta.yoast_wpseo_title}
        description={meta.yoast_wpseo_metadesc}
      />
      <PageHeader title={page.title} image={page.acf.banner_image} />
      <main
        className="content__container services__wrapper"
        dangerouslySetInnerHTML={{ __html: page.acf.description }}
      />
    </Layout>
  )
}

export default Page
